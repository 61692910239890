@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.keyword-finder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3e50;
  margin: auto;
  margin-top: 60px;
  gap: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.header {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #bec3c7;
  animation: fadeInDown 0.5s ease;
}

.header span {
  color: #e74c3c;
  font-weight: 700;
}

.input-field {
  width: 60%;
  max-width: 600px;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 25px;
  border: 1px solid #bdc3c7;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.input-field:focus {
  border-color: #e74c3c;
  box-shadow: 0 4px 15px rgba(231, 76, 60, 0.2);
}

.generate-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  font-size: 18px;
  border-radius: 25px;
  background: #e74c3c;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 0.5s ease;
}

.generate-btn:hover {
  background: #c0392b;
}

.loading-text {
  font-size: 18px;
  margin-top: 10px;
  color: #e74c3c;
  animation: fadeIn 0.5s ease;
}

.tags-container {
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.5s ease;
}

.tag {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease;
}

.tag-green {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.tag-yellow {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}

.tag:hover {
  background: #f0f0f0;
}

.copy-btn {
  background: #e74c3c;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;
}

.copy-btn:hover {
  background: #c0392b;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInUp 0.5s ease;
}

.char-counter {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

.explanation {
  margin-top: 20px;
  font-size: 16px;
}

.green-text {
  color: #155724;
}

.yellow-text {
  color: #856404;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
